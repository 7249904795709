import {
    isFileAttachment,
    isMediaAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import { emptyTaskList } from '@modules/tasks/models/task.utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskFeatureState, taskFeatureKey } from './task.reducer';

const selectCommunitiesFeature = createFeatureSelector<{
    [taskFeatureKey]: TaskFeatureState;
}>('communities');

const selectTaskFeature = createSelector(
    selectCommunitiesFeature,
    (state) => state[taskFeatureKey],
);

export const selectTasksByPostId = (postId: number) =>
    createSelector(selectTaskFeature, (taskState) =>
        taskState.postId === postId ? taskState.tasksDetail : emptyTaskList(),
    );

export const selectAttachmentsByTaskId = (taskId: number) =>
    createSelector(
        selectTaskFeature,
        (taskState) =>
            taskState.tasksAttachments.find(
                (_task) => _task.taskId === taskId,
            ) || null,
    );

export const selectMediaAttachments = (taskId: number) =>
    createSelector(selectAttachmentsByTaskId(taskId), (taskAttachmentList) =>
        taskAttachmentList?.attachments.attachments.filter(isMediaAttachment),
    );

export const selectFileAttachments = (taskId: number) =>
    createSelector(selectAttachmentsByTaskId(taskId), (taskAttachmentList) =>
        taskAttachmentList?.attachments.attachments.filter(isFileAttachment),
    );

export const selectTaskById = (postId: number, taskId: number) =>
    createSelector(
        selectTasksByPostId(postId),
        (tasks) => tasks.list.find((t) => t.id === taskId) || null,
    );

export const selectTasksLoadingByPostId = (postId: number) =>
    createSelector(selectTaskFeature, (taskState) =>
        taskState.postId === postId ? taskState.isLoading : null,
    );

export const selectTasksFetchingByPostId = (postId: number) =>
    createSelector(selectTaskFeature, (taskState) =>
        taskState.postId === postId ? taskState.tasksDetail.isFetching : null,
    );

export const selectTasksPageTokenInfo = createSelector(
    selectTaskFeature,
    (taskState) => taskState.tasksDetail.nextPageToken,
);

export const selectTasksError = createSelector(
    selectTaskFeature,
    (taskState) => taskState.createTaskError,
);
