import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { PaginatedList, toPaginatedList } from '@interacta-shared/util';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Observable, map, withLatestFrom } from 'rxjs';
import { toDigitalWorkplaceDefinition } from '../models/digital-workplace.deserialize';
import { DigitalWorkplaceDefinition } from '../models/digital-workplace.model';

@Injectable({
    providedIn: 'root',
})
export class DigitalWorkplaceService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/digital-workplace/data`;
    private readonly http = inject(HttpClient);
    private readonly configurationService = inject(ConfigurationService);
    private readonly communitiesStateService = inject(CommunitiesStateService);

    getHomeDefinitions(
        pageToken?: string | null,
    ): Observable<PaginatedList<DigitalWorkplaceDefinition>> {
        return this.http
            .post<{ items: any[] }>(`${this.baseUrl}/home-pages`, {
                pageToken,
                pageSize: 10,
                calculateTotalItemsCount: false,
            })
            .pipe(
                map((res) => toPaginatedList<any>(res)),
                withLatestFrom(
                    this.communitiesStateService.state.communityList$,
                ),
                map(([paginatedList, communityList]) => {
                    return {
                        ...paginatedList,
                        list: paginatedList.list.map((d) =>
                            toDigitalWorkplaceDefinition(
                                d,
                                this.configurationService,
                                communityList,
                                true,
                            ),
                        ),
                    };
                }),
            );
    }

    markHomeAsViewed(id: number): Observable<number> {
        return this.http
            .put<{
                viewedTimestamp: number;
            }>(`${this.baseUrl}/mark-home-as-viewed/${id}`, {})
            .pipe(map((result) => result.viewedTimestamp));
    }
}
