import {
    StandardTask,
    TaskAttachmentList,
    TaskList,
    TaskState,
    toggleTaskState,
} from '@modules/tasks/models/task.model';
import { emptyTaskList } from '@modules/tasks/models/task.utils';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import * as fapi from './task-api.actions';
import * as fa from './task.actions';

export const taskFeatureKey = 'task';

export interface TaskFeatureState {
    postId: number | null;
    tasksDetail: TaskList;
    tasksAttachments: TaskAttachmentList[];
    isLoading: boolean; // Loading operations
    createTaskError: any;
    editingTask: StandardTask | null;
}

const initialState: TaskFeatureState = {
    postId: null,
    tasksDetail: emptyTaskList(),
    tasksAttachments: [],
    isLoading: false,
    createTaskError: null,
    editingTask: null,
};

export const taskReducer = createReducer<TaskFeatureState>(
    initialState,

    on(fa.fetchTasks, fa.fetchTasksUntilId, (state, props) =>
        produce(state, (draft) => {
            draft.tasksDetail.isFetching = true;
            draft.postId = props.postId;
        }),
    ),

    on(fapi.fetchTasksSuccess, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            if (state.postId === props.postId) {
                draft.postId = props.postId;
                draft.tasksDetail = {
                    ...props.tasks,
                    list: props.filters.pageToken
                        ? [...state.tasksDetail.list, ...props.tasks.list]
                        : props.tasks.list,
                    nextPageToken: props.tasks.nextPageToken,
                    isFetching: false,
                };
            }
        }),
    ),

    on(fapi.fetchTaskAttachmentsSuccess, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            const taskAttachmentIndex = state.tasksAttachments.findIndex(
                (_) => _.taskId === props.taskId,
            );
            if (taskAttachmentIndex < 0) {
                draft.tasksAttachments.push({
                    taskId: props.taskId,
                    attachments: props.attachments,
                });
            } else {
                draft.tasksAttachments[taskAttachmentIndex] = {
                    taskId: props.taskId,
                    attachments: props.attachments,
                };
            }
        }),
    ),

    on(fa.fetchTaskAttachments, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(fa.fetchTasksPage, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(fa.createTask, (state) => ({
        ...state,
        isLoading: true,
        createTaskError: null,
    })),

    on(fapi.fetchTaskForEditSuccess, (state, props) => ({
        ...state,
        editingTask: props.task,
    })),

    on(fapi.createTaskError, (state, props) => ({
        ...state,
        createTaskError: props.error,
    })),

    on(fa.editTask, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(fa.copyTask, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(fa.deleteTask, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(fapi.fetchTasksPageSuccess, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            draft.isLoading = false;
            draft.tasksDetail = {
                ...props.tasks,
                list: [...state.tasksDetail.list, ...props.tasks.list],
                nextPageToken: props.tasks.nextPageToken,
                isFetching: false,
            };
        }),
    ),

    on(fa.toggleState, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            draft.isLoading = true;
            const draftTask = draft.tasksDetail.list.find(
                (t) => t.id === props.taskId,
            );
            if (draftTask) {
                draftTask.state = toggleTaskState(draftTask.state);
                if (draftTask.state === TaskState.CLOSED) {
                    draftTask.closingTimestamp = new Date();
                }
            }
        }),
    ),

    on(fapi.toggleStateError, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            const draftTask = draft.tasksDetail.list.find(
                (t) => t.id === props.taskId,
            );
            if (draftTask?.state)
                draftTask.state = toggleTaskState(draftTask.state);
        }),
    ),

    on(
        fapi.fetchTasksError,
        fapi.fetchTasksPageError,
        fapi.createTaskSuccess,
        fapi.createTaskError,
        fapi.editTaskSuccess,
        fapi.editTaskError,
        fapi.deleteTaskSuccess,
        fapi.deleteTaskError,
        fapi.copyTaskSuccess,
        fapi.copyTaskError,
        fapi.toggleStateSuccess,
        fapi.toggleStateError,
        fapi.fetchTaskAttachmentsError,
        fapi.fetchTaskAttachmentsSuccess,
        fapi.fetchTaskForEditError,
        fapi.fetchTaskForEditSuccess,
        (state, _props) => ({ ...state, isLoading: false }),
    ),

    on(fapi.toggleSubtaskStateSuccess, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            if (draft.postId === props.postId) {
                const draftTask = draft.tasksDetail.list.find(
                    (t) => t.id === props.taskId,
                );
                if (draftTask) {
                    const draftSubtask = draftTask.subTasks.find(
                        (s) => s.id === props.subtask.id,
                    );
                    if (draftSubtask) draftSubtask.state = props.subtask.state;
                }
            }
        }),
    ),

    on(fapi.toggleSubtaskStateError, (state, props) =>
        produce(state, (draft: TaskFeatureState) => {
            if (draft.postId === props.postId) {
                const draftTask = draft.tasksDetail.list.find(
                    (t) => t.id === props.taskId,
                );

                const draftSubtask = draftTask?.subTasks.find(
                    (s) => s.id === props.subtaskId,
                );
                if (draftSubtask) draftSubtask.state = props.state;
                draft.createTaskError = props.error;
            }
        }),
    ),

    on(fa.editReminders, (state) =>
        produce(state, (draft: TaskFeatureState) => {
            draft.isLoading = true;
        }),
    ),

    on(fapi.editRemindersSuccess, (state, props) => ({
        ...state,
        isLoading: false,
        tasksDetail: {
            ...state.tasksDetail,
            list: state.tasksDetail.list.map((t) =>
                t.id === props.task.id ? props.task : t,
            ),
        },
    })),

    on(fapi.editRemindersError, (state, props) => ({
        ...state,
        isLoading: false,
        createTaskError: props.error,
    })),

    on(fa.editWatchers, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(fapi.editWatchersSuccess, (state, props) => ({
        ...state,
        isLoading: false,
        tasksDetail: {
            ...state.tasksDetail,
            list: state.tasksDetail.list.map((t) =>
                t.id === props.task.id ? props.task : t,
            ),
        },
    })),

    on(fapi.editWatchersError, (state) => ({
        ...state,
        isLoading: false,
    })),
);
