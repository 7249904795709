import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GallerySourceService } from '@modules/state/services/gallery-source.service';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { CommunitiesModuleService } from './services/communities-module.service';
import { CommunitiesRouterEffects } from './store/communities-router.effects';
import { CommunityEffects } from './store/community/community.effects';
import {
    communityFeatureKey,
    communityReducer,
} from './store/community/community.reducer';
import { DifferentialEffects } from './store/differential/differential.effects';
import { HomePagesEffects } from './store/home-pages/home-pages.effects';
import {
    homePagesFeatureKey,
    homePagesReducer,
} from './store/home-pages/home-pages.reducer';
import { CommentsEffects } from './store/post-comments.effects';
import { PostEffects } from './store/post/post.effects';
import { postFeatureKey, postReducer } from './store/post/post.reducer';
import { TaskEffects } from './store/task/task.effects';
import { taskFeatureKey, taskReducer } from './store/task/task.reducer';
import { addCommunitiesGallerySources } from './utils/communities-gallery-sources';

export const ROUTES: Routes = [];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(ROUTES),
        StoreModule.forFeature('communities', {
            [postFeatureKey]: postReducer,
            [taskFeatureKey]: taskReducer,
            [communityFeatureKey]: communityReducer,
            [homePagesFeatureKey]: homePagesReducer,
        }),
        EffectsModule.forFeature([
            PostEffects,
            TaskEffects,
            CommunityEffects,
            DifferentialEffects,
            CommentsEffects,
            CommunitiesRouterEffects,
            HomePagesEffects,
        ]),
    ],
    exports: [],
})
export class CommunitiesModule {
    constructor(
        communitiesModuleService: CommunitiesModuleService,
        store: Store,
        gallerySourceService: GallerySourceService,
    ) {
        communitiesModuleService.initialize();
        addCommunitiesGallerySources(store, gallerySourceService);
    }

    static forRoot(): ModuleWithProviders<CommunitiesModule> {
        return {
            ngModule: CommunitiesModule,
            providers: [CommunitiesModuleService],
        };
    }
}
