import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filterMap, isDefined } from '@interacta-shared/util';
import {
    adminV2Routes,
    dashboard,
    dashboardCommunity,
    dashboardRoutes,
    insightsRoutes,
    manageRoutes,
    peopleRoutes,
} from '@modules/app-routing/routes';
import { parseHashtagsInRoute } from '@modules/communities/models/hashtag/hashtag.utils';
import { AppSelectors, AppState } from '@modules/core/store';
import {
    defaultPostOrderClause,
    emptyPostFilters,
    movingFromAttachmentToPostView,
} from '@modules/post/models/filter-post/filter-post.utils';
import { DashboardView } from '@modules/post/models/post-list.model';
import { StateService } from '@modules/state/services/state.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import produce from 'immer';
import {
    concatMap,
    filter,
    first,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { digitalWorkplaceRoutes } from '../../app-routing/routes';
import { FilterCommunitiesService } from '../services/filter-communities.service';
import * as fromPostActions from './post/post.actions';
import * as PostSelectors from './post/post.selectors';

const UPDATE_FILTERS_ROUTES = [
    ...dashboardRoutes,
    ...manageRoutes,
    ...insightsRoutes,
    ...peopleRoutes,
    ...digitalWorkplaceRoutes,
    ...adminV2Routes,
];

@Injectable()
export class CommunitiesRouterEffects {
    navigateToTheOnlyCommunity$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromRouter.routerNavigatedAction),
                switchMap(() =>
                    this.store
                        .select(AppSelectors.selectRouteState)
                        .pipe(first()),
                ),
                filterMap((route) => route),
                filter((route) => route.appRoute === dashboard),
                switchMap((route) =>
                    this.stateService.communitiesState.communityTree$.pipe(
                        filter(isDefined),
                        first(),
                        map((tree) => ({
                            route,
                            workspaceList: tree.workspaceList,
                        })),
                    ),
                ),
                filterMap(({ workspaceList }) =>
                    workspaceList.length === 1 &&
                    workspaceList[0].communities?.length === 1
                        ? workspaceList[0].communities?.[0].id
                        : undefined,
                ),
                tap((communityId) => {
                    this.router.navigate([dashboardCommunity, communityId]);
                }),
            ),
        { dispatch: false },
    );

    updateFilterOnRouteNavigation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromRouter.routerNavigatedAction),
            concatMap(() =>
                this.store.select(AppSelectors.selectRouteState).pipe(first()),
            ),
            filterMap((route) => route),
            filter(
                (route) =>
                    !!route.appRoute &&
                    UPDATE_FILTERS_ROUTES.includes(route.appRoute),
            ),
            withLatestFrom(
                this.store.select(
                    PostSelectors.selectCurrentFilters('dashboard'),
                ),
                this.store.select(PostSelectors.selectFetchInfo('dashboard')),
            ),
            switchMap(([route, filters, fetchInfo]) =>
                this.stateService.communitiesState.communityTree$.pipe(
                    filter(isDefined),
                    first(),
                    map((tree) =>
                        route.params.communityId &&
                        (!route.appRoute ||
                            !insightsRoutes.includes(route.appRoute))
                            ? tree.communityList.find(
                                  (c) =>
                                      c.id === Number(route.params.communityId),
                              )
                            : null,
                    ),
                    map((community) => ({
                        route,
                        community,
                        filters,
                        fetchInfo,
                    })),
                ),
            ),
            tap(({ route, community }) => {
                if (!community && route.appRoute === dashboardCommunity) {
                    this.router.navigate([dashboard]);
                }
            }),
            filterMap((data) =>
                !data.community && data.route.appRoute === dashboardCommunity
                    ? undefined
                    : data,
            ),
            map(({ route, community, filters, fetchInfo }) => {
                const defaultFilters = {
                    ...emptyPostFilters(),
                    communityId: community?.id ?? undefined,
                };
                const cachedFilters =
                    this.filterCommunitiesService.getFilterPostListCache(
                        community?.id ?? 'dashboard',
                    );

                if (
                    this.stateService.uiState.communitiesUI.staticData()?.[
                        community?.id ?? 'dashboard'
                    ].dashboardView !== DashboardView.Attachments &&
                    movingFromAttachmentToPostView(
                        cachedFilters?.order?.orderBy,
                    )
                ) {
                    cachedFilters.order = defaultPostOrderClause();
                }

                return fromPostActions.changeFilters({
                    fetchType: 'dashboard',
                    updatedFilters: {
                        ...defaultFilters,
                        ...cachedFilters,
                        hashtag:
                            isDefined(route.queryParams.filterTag) &&
                            isDefined(community)
                                ? parseHashtagsInRoute(
                                      route.queryParams.filterTag,
                                      filters,
                                      community.id,
                                  )
                                : (cachedFilters?.hashtag ?? null),
                    },
                    forcePostFetch: fetchInfo.isStale,
                });
            }),
        ),
    );

    convertEntityQueryParam$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromRouter.routerNavigatedAction),
                concatMap(() =>
                    this.store
                        .select(AppSelectors.selectRouteState)
                        .pipe(first()),
                ),
                filterMap((route) => route),
                filter((route) => route.appBaseRoute === 'post-detail'),
                filter((route) => isDefined(route.queryParams.entity)),
                tap(({ params, queryParams }) => {
                    const withoutEntity = produce(queryParams, (draft) => {
                        delete draft.entity;
                    });
                    this.router.navigate(
                        ['/post', params.id, queryParams.entity],
                        { replaceUrl: true, queryParams: withoutEntity },
                    );
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private router: Router,
        private stateService: StateService,
        private filterCommunitiesService: FilterCommunitiesService,
    ) {}
}
