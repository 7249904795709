import { Injectable } from '@angular/core';
import { ErrorService } from '@interacta-shared/data-access-error';
import { filterMap } from '@interacta-shared/util';
import { AppState } from '@modules/core/store';
import { adminFlush, flush } from '@modules/core/store/app.actions';
import { DigitalWorkplaceService } from '@modules/digital-workplace/services/digital-workplace.service';
import * as HeaderActions from '@modules/header/store/header.actions';
import * as SidebarActions from '@modules/sidebar/store/sidebar.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    catchError,
    concatMap,
    exhaustMap,
    filter,
    groupBy,
    map,
    mergeMap,
    of,
    tap,
    withLatestFrom,
} from 'rxjs';
import {
    clear,
    fetchHomeDefinitions,
    fetchHomeDefinitionsError,
    fetchHomeDefinitionsSuccess,
    markHomeAsViewed,
    markHomeAsViewedError,
    markHomeAsViewedSuccess,
} from './home-pages.actions';
import { selectHomeDefinitions } from './home-pages.selectors';

@Injectable()
export class HomePagesEffects {
    fetchHomeDefinitions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchHomeDefinitions),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(this.store.select(selectHomeDefinitions)),
                ),
            ),
            filter(
                ([{ force }, homeDefinitions]) =>
                    force || homeDefinitions == null,
            ),
            exhaustMap(() =>
                this.digitalWorkplaceService.getHomeDefinitions().pipe(
                    map((definitions) =>
                        fetchHomeDefinitionsSuccess({
                            homeDefinitions: definitions.list,
                        }),
                    ),
                    catchError((error) =>
                        of(fetchHomeDefinitionsError({ error })),
                    ),
                ),
            ),
        ),
    );

    setShowDashboardLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchHomeDefinitionsSuccess),
            map(({ homeDefinitions }) =>
                SidebarActions.setShowDashboardLink({
                    showDashboardLink: homeDefinitions.length > 0,
                }),
            ),
        ),
    );

    setHomeSelectorItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchHomeDefinitionsSuccess, markHomeAsViewedSuccess),
            concatMap(() =>
                of(null).pipe(
                    withLatestFrom(this.store.select(selectHomeDefinitions)),
                ),
            ),
            filterMap(([_, homeDefinitions]) => homeDefinitions),
            map((homeDefinitions) =>
                HeaderActions.setHomeSelectorItems({
                    items: homeDefinitions.map((home) => ({
                        id: home.id,
                        name: home.name,
                        isNew: home.viewedTimestamp == null,
                    })),
                }),
            ),
        ),
    );

    markHomeAsViewed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(markHomeAsViewed),
            groupBy(({ id }) => id),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ id }) =>
                        this.digitalWorkplaceService.markHomeAsViewed(id).pipe(
                            map((viewedTimestamp) =>
                                markHomeAsViewedSuccess({
                                    id,
                                    viewedTimestamp,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    markHomeAsViewedError({
                                        id,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    appFlush$ = createEffect(() =>
        this.actions$.pipe(
            ofType(flush, adminFlush),
            map(() => clear()),
        ),
    );

    fetchError$ = createEffect(
        () =>
            this.actions$
                .pipe(ofType(fetchHomeDefinitionsError, markHomeAsViewedError))
                .pipe(tap(({ error }) => this.errorService.handle(error))),
        { dispatch: false },
    );

    constructor(
        private digitalWorkplaceService: DigitalWorkplaceService,
        private store: Store<AppState>,
        private actions$: Actions,
        private errorService: ErrorService,
    ) {}
}
