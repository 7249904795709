import { Injectable } from '@angular/core';
import { openProTip } from '@interacta-shared/feature-tip';
import { isDefined } from '@interacta-shared/util';
import { dashboard, dashboardCommunity } from '@modules/app-routing/routes';
import { CommunitiesService } from '@modules/communities/services/communities.service';
import { FilterCommunitiesService } from '@modules/communities/services/filter-communities.service';
import { CommunityActions } from '@modules/communities/store';
import * as CommunitySelectors from '@modules/communities/store/community/community.selectors';
import * as PostSelectors from '@modules/communities/store/post/post.selectors';
import { HomeCommunityIndex } from '@modules/core';
import { BoundedTipService } from '@modules/core/services/bounded-tip.service';
import { AppState } from '@modules/core/store/app.reducer';
import { DashboardService } from '@modules/dashboard/services/dashboard-page.service';
import { ActualizeFiltersService } from '@modules/post-filters/services/actualize-filters.service';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import {
    emptyPostFilters,
    filterIsEmpty,
    filtersAreEquals,
} from '@modules/post/models/filter-post/filter-post.utils';
import { QuickFilterType } from '@modules/post/models/quick-filters/quick-filters.model';
import { isSavedQuickFilter } from '@modules/post/models/quick-filters/quick-filters.util';
import { PostListService } from '@modules/post/services/post-list.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, merge, of } from 'rxjs';
import {
    catchError,
    concatMap,
    exhaustMap,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { AttachmentService } from '../../../post/services/attachment.service';
import { changeFiltersSuccess } from '../post/post-api.actions';
import * as postActions from '../post/post.actions';
import * as fromActionsApi from './community-api.actions';
import * as fromActions from './community.actions';
import {
    selectActiveQuickFilter,
    selectInitializedQuickFilters,
    selectQuickFilters,
} from './community.selectors';

@Injectable()
export class CommunityEffects {
    fetchAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchAttachments),
            switchMap(({ communityId, attachmentType, pageSize }) => {
                const filter: IPostFilters = {
                    ...this.filterCommunitiesService.getFilterPostListCache(
                        communityId,
                    ),
                    pageSize,
                    mimeTypeCategory: attachmentType,
                    calculateTotalItemsCount: true,
                };
                return this.postListService
                    .communityAttachmentList(filter)
                    .pipe(
                        map(
                            ({
                                attachmentsList,
                                mediasCount,
                                documentsCount,
                            }) =>
                                fromActionsApi.fetchAttachmentsSuccess({
                                    communityId,
                                    attachmentsList,
                                    attachmentType,
                                    mediasCount,
                                    documentsCount,
                                }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.fetchAttachmentsError({
                                    communityId,
                                    attachmentType,
                                    error,
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    fetchAttachmentsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchAttachmentsPage),
            exhaustMap(
                ({ communityId, attachmentType, nextPageToken, pageSize }) => {
                    const filter: IPostFilters = {
                        ...this.filterCommunitiesService.getFilterPostListCache(
                            communityId,
                        ),
                        pageSize,
                        pageToken: nextPageToken,
                        mimeTypeCategory: attachmentType,
                        calculateTotalItemsCount: false,
                    };
                    return this.postListService
                        .communityAttachmentList(filter)
                        .pipe(
                            map(({ attachmentsList }) =>
                                fromActionsApi.fetchAttachmentsPageSuccess({
                                    communityId,
                                    attachmentsList,
                                    attachmentType,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.fetchAttachmentsPageError({
                                        communityId,
                                        attachmentType,
                                        error,
                                    }),
                                ),
                            ),
                        );
                },
            ),
        ),
    );

    deleteAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteAttachments),
            mergeMap(({ ids }) =>
                this.attachmentService.deleteBatchAttachments(ids).pipe(
                    map((deletedIds) =>
                        fromActionsApi.deleteAttachmentsSuccess({
                            ids: deletedIds,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.deleteAttachmentsError({
                                ids,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getRecentCommunities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getRecentCommunities),
            switchMap(() =>
                this.communitiesService.getRecentCommunities().pipe(
                    map((communities) =>
                        fromActionsApi.getRecentCommunitiesSuccess({
                            communities,
                        }),
                    ),
                    catchError((error) =>
                        of(fromActionsApi.getRecentCommunitiesError({ error })),
                    ),
                ),
            ),
        ),
    );

    massiveUploadHashtags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.massiveUploadHashtags),
            mergeMap(({ attachmentIds, addHashtagIds, removeHashtagIds }) =>
                this.attachmentService
                    .massiveUploadHashtags(
                        attachmentIds,
                        addHashtagIds,
                        removeHashtagIds,
                    )
                    .pipe(
                        map((updatedAttachments) =>
                            fromActionsApi.massiveUploadHashtagsSuccess({
                                updatedAttachments,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.massiveUploadHashtagsError({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    fetchQuickFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchQuickFilters),
            mergeMap(() =>
                this.communitiesService.getQuickFilters().pipe(
                    map((quickFilters) =>
                        fromActionsApi.fetchQuickFiltersSuccess({
                            quickFilters,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.fetchQuickFiltersError({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    initializeQuickFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.initializeQuickFilters),
            withLatestFrom(this.store.select(selectQuickFilters)),
            filter(
                ([{ homeCommunityIndex }, quickFilters]) =>
                    !quickFilters ||
                    !quickFilters[homeCommunityIndex] ||
                    quickFilters[homeCommunityIndex].some(
                        (f) => !isSavedQuickFilter(f),
                    ),
            ),
            switchMap(
                ([{ homeCommunityIndex, initializeFilters }, quickFilters]) =>
                    (quickFilters &&
                    quickFilters[homeCommunityIndex] &&
                    quickFilters[homeCommunityIndex].every(isSavedQuickFilter)
                        ? this.communitiesService.getQuickFilters()
                        : this.communitiesService.initializeQuickFilters(
                              homeCommunityIndex === 'dashboard'
                                  ? null
                                  : homeCommunityIndex,
                              initializeFilters,
                          )
                    ).pipe(
                        map((quickFilters) =>
                            fromActionsApi.initializeQuickFiltersSuccess({
                                quickFilters,
                                homeCommunityIndex,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.initializeQuickFiltersError({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    changeFiltersAfterChangeQuickFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.setActiveQuickFilter),
            withLatestFrom(this.store.select(selectInitializedQuickFilters)),
            mergeMap(
                ([
                    { quickFilter, communityId, clearPreviousFilter },
                    initializedQuickFilters,
                ]) => {
                    if (
                        quickFilter &&
                        quickFilter.type === QuickFilterType.CUSTOM &&
                        typeof quickFilter.id === 'number' &&
                        !initializedQuickFilters[quickFilter.id]
                    ) {
                        //actualization
                        return this.actualizeFiltersService
                            .actualizeFilters$(quickFilter, communityId)
                            .pipe(
                                map((quickFilter) => ({
                                    communityId,
                                    quickFilter,
                                    setInitialized: true,
                                    clearPreviousFilter,
                                })),
                            );
                    } else {
                        return of(quickFilter).pipe(
                            map((quickFilter) => ({
                                communityId,
                                quickFilter,
                                setInitialized: false,
                                clearPreviousFilter,
                            })),
                        );
                    }
                },
            ),
            mergeMap(
                ({
                    communityId,
                    quickFilter,
                    setInitialized,
                    clearPreviousFilter,
                }) => {
                    const actions = [
                        postActions.changeFilters({
                            fetchType: 'dashboard',
                            updatedFilters: {
                                ...emptyPostFilters(),
                                ...quickFilter?.filters,
                                communityId:
                                    typeof communityId === 'number'
                                        ? communityId
                                        : undefined,
                            },
                            clearPreviousFilter,
                        }),
                        setInitialized && quickFilter
                            ? CommunityActions.setInitializedQuickFilters({
                                  quickFilter,
                                  communityId,
                              })
                            : null,
                    ].filter(isDefined);

                    return from(actions);
                },
            ),
        ),
    );

    removeQuickFilterAfterFilterUpdates$ = createEffect(() =>
        merge(
            this.actions$.pipe(
                ofType(postActions.addFilters),
                map(({ addFilters }) => addFilters.communityId),
            ),
            this.actions$.pipe(
                ofType(postActions.removeFilters),
                map(() => undefined),
            ),
            this.actions$.pipe(
                ofType(postActions.changeFilters),
                filter((action) => !!action.forcePostFetch),
                map(({ updatedFilters }) => updatedFilters.communityId),
            ),
        ).pipe(
            withLatestFrom(this.dashboardService.currentCommunity$),
            mergeMap(([communityId, community]) =>
                of(
                    CommunityActions.resetActiveQuickFilter({
                        communityId:
                            communityId ?? community?.id ?? 'dashboard',
                    }),
                ),
            ),
        ),
    );

    createCustomFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.createCustomFilter),
            switchMap(({ filter, communityId, callerLocation }) =>
                this.communitiesService.saveCustomFilter(filter).pipe(
                    map((quickFilter) =>
                        fromActionsApi.createCustomFilterSuccess({
                            quickFilter,
                            communityId,
                            callerLocation,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.createCustomFilterError({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    showSuccessfulFilterCreationTip$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActionsApi.createCustomFilterSuccess,
                fromActionsApi.modifyCustomFilterSuccess,
            ),
            map(() =>
                openProTip({
                    title: 'DASHBOARD.FILTERS.SUCCESSFUL_FILTER_CREATION_TITLE',
                    message:
                        'DASHBOARD.FILTERS.SUCCESSFUL_FILTER_CREATION_DESCRIPTION',
                    closeBehavior: 'duration',
                    translateParams: undefined,
                    actionCallbacks: undefined,
                    image: 'reading',
                }),
            ),
        ),
    );

    setActiveQuickFilterAfterCreation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.createCustomFilterSuccess),
            filter(({ callerLocation }) => callerLocation === 'sidebar'),
            map(({ quickFilter, communityId }) =>
                fromActions.setActiveQuickFilter({
                    communityId,
                    quickFilter,
                    clearPreviousFilter: true,
                }),
            ),
        ),
    );

    setActiveQuickFilterAfterEditQuickFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.modifyCustomFilterSuccess),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(this.store.select(selectActiveQuickFilter)),
                ),
            ),
            filter(
                ([{ filter, homeCommunityIndex }, activeQuickFilter]) =>
                    isDefined(activeQuickFilter) &&
                    activeQuickFilter[homeCommunityIndex]?.id === filter.id,
            ),
            map(([{ filter, homeCommunityIndex }]) =>
                fromActions.setActiveQuickFilter({
                    communityId: homeCommunityIndex,
                    quickFilter: filter,
                    clearPreviousFilter: true,
                }),
            ),
        ),
    );

    removeCustomFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteCustomFilter),
            switchMap(({ filterId, homeCommunityIndex }) =>
                this.communitiesService
                    .deleteCustomFilter(
                        filterId,
                        homeCommunityIndex !== 'dashboard'
                            ? homeCommunityIndex
                            : null,
                    )
                    .pipe(
                        map((filters) =>
                            fromActionsApi.deleteCustomFilterSuccess({
                                filters,
                                homeCommunityIndex,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.deleteCustomFilterError({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    actualizeQuickFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.actualizeQuickFilter),
            switchMap(({ quickFilter, homeCommunityIndex }) =>
                this.actualizeFiltersService
                    .actualizeFilters$(quickFilter, homeCommunityIndex)
                    .pipe(
                        map((quickFilter) =>
                            fromActionsApi.actualizeQuickFilterSuccess({
                                quickFilter,
                                homeCommunityIndex,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.actualizeQuickFilterError({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    modifyCustomFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.modifyCustomFilter),
            switchMap(({ filter, homeCommunityIndex }) =>
                this.communitiesService.modifyCustomFilter(filter).pipe(
                    map((quickFilter) =>
                        fromActionsApi.modifyCustomFilterSuccess({
                            filter: quickFilter,
                            homeCommunityIndex,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.modifyCustomFilterError({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    changeQuickFiltersOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.changeQuickFiltersOrder),
            concatMap(({ quickFilters, homeCommunityIndex }) =>
                this.communitiesService
                    .changeQuickFiltersOrder(
                        homeCommunityIndex !== 'dashboard'
                            ? homeCommunityIndex
                            : null,
                        quickFilters,
                    )
                    .pipe(
                        map((filters) =>
                            fromActionsApi.changeQuickFiltersOrderSuccess({
                                filters,
                                homeCommunityIndex,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.changeQuickFiltersOrderError({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    showUndoTip$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeFiltersSuccess),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(PostSelectors.selectPreviousFilter),
                        this.store.select(
                            CommunitySelectors.selectPreviousActiveQuickFilter,
                        ),
                        this.store.select(
                            CommunitySelectors.selectActiveQuickFilter,
                        ),
                    ),
                ),
            ),
            filter(
                ([action, prevFilter, prevQickFilter, activeQuickFilters]) => {
                    const _prevFilter = prevFilter
                        ? { ...prevFilter, order: null }
                        : null;
                    const prevFilterExists =
                        !!_prevFilter && !filterIsEmpty(_prevFilter, false);
                    const filterIsChanged =
                        prevFilterExists &&
                        !filtersAreEquals(_prevFilter, {
                            ...action.updatedFilters,
                            order: null,
                        });
                    const prevQickFilterExists = !!prevQickFilter;
                    const activeQuickFiltersExisits =
                        prevFilterExists &&
                        !!activeQuickFilters[
                            _prevFilter.communityId ?? 'dashboard'
                        ];
                    return (
                        prevFilterExists &&
                        filterIsChanged &&
                        (prevQickFilterExists || activeQuickFiltersExisits)
                    );
                },
            ),
            switchMap(([_, prevFilter, prevQuickFilter]) =>
                this.tipService
                    .openBoundedTip({
                        message: 'DASHBOARD.FILTERS.APPLIED',
                        closeBehavior: 'duration',
                        tipActions: [
                            {
                                id: 'undo',
                                label: 'BUTTON.LABEL_BUTTON_CANCEL',
                                data: {},
                            },
                        ],
                        image: 'empty',
                        payload: { boundary: [dashboard, dashboardCommunity] },
                        hideCloseButton: true,
                        direction: 'horizontal',
                    })
                    .pipe(map(() => ({ prevFilter, prevQuickFilter }))),
            ),
            map(({ prevFilter, prevQuickFilter }) => {
                if (prevFilter && prevQuickFilter) {
                    return fromActions.setActiveQuickFilter({
                        communityId: (prevFilter.communityId ??
                            'dashboard') as HomeCommunityIndex,
                        quickFilter: {
                            ...prevQuickFilter.quickFilter,
                            filters: {
                                ...prevQuickFilter.quickFilter.filters,
                                ...prevFilter,
                            },
                        },
                        clearPreviousFilter: true,
                    });
                } else {
                    return postActions.changeFilters({
                        fetchType: 'dashboard',
                        updatedFilters: prevFilter ?? emptyPostFilters(),
                        clearPreviousFilter: true,
                        forcePostFetch: true,
                    });
                }
            }),
        ),
    );

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private postListService: PostListService,
        private filterCommunitiesService: FilterCommunitiesService,
        private attachmentService: AttachmentService,
        private communitiesService: CommunitiesService,
        private dashboardService: DashboardService,
        private tipService: BoundedTipService,
        private actualizeFiltersService: ActualizeFiltersService,
    ) {}
}
