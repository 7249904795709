import { lastLoading } from '@interacta-shared/util';
import {
    AttachmentCategoryType,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    emptyAttachmentsList,
    isFileAttachment,
    isMediaAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import { GallerySourceService } from '@modules/state/services/gallery-source.service';
import { Store } from '@ngrx/store';
import { map, of } from 'rxjs';
import { CommunityActions, PostActions } from '../store';
import {
    selectCommunityDocuments,
    selectCommunityMediaAttachments,
    selectCommunityMixedAttachments,
} from '../store/community/community.selectors';
import {
    selectCommentAttachments,
    selectDocumentList,
    selectLinkedPostFilePickerAttachments,
    selectMediaList,
    selectPostCommentDocumentList,
    selectPostCommentMediaList,
    selectPostFilePickerAttachments,
    selectPostFilePickerDocumentList,
    selectPostFilePickerMediaList,
    selectScreenFilePickerAttachments,
    selectWorkflowHistoryAttachments,
} from '../store/post/post.selectors';
import {
    selectFileAttachments,
    selectMediaAttachments,
} from '../store/task/task.selectors';

export function addCommunitiesGallerySources(
    store: Store,
    gallerySourceService: GallerySourceService,
): void {
    gallerySourceService.addSource('community', {
        sourceFn: (detail) =>
            store.select(
                detail.type === AttachmentCategoryType.DOCUMENT
                    ? selectCommunityDocuments
                    : detail.type === AttachmentCategoryType.MULTIMEDIA
                      ? selectCommunityMediaAttachments
                      : selectCommunityMixedAttachments,
            ),
        fetchFn: ({ detail, pageToken, pageSize }) => {
            if (pageToken) {
                store.dispatch(
                    CommunityActions.fetchAttachmentsPage({
                        communityId: detail.communityId,
                        nextPageToken: pageToken,
                        attachmentType: detail.type,
                        pageSize,
                    }),
                );
            }
        },
    });

    gallerySourceService.addSource('post', {
        sourceFn: (detail) =>
            store.select(
                detail.type === AttachmentCategoryType.DOCUMENT
                    ? selectDocumentList(detail.postId)
                    : selectMediaList(detail.postId),
            ),
        fetchFn: ({ detail, pageToken, pageSize }) => {
            store.dispatch(
                PostActions.fetchAttachmentsPage({
                    postId: detail.postId,
                    nextPageToken: pageToken,
                    attachmentType: detail.type,
                    pageSize,
                    entityTypes: ['post'],
                }),
            );
        },
    });

    gallerySourceService.addSource('postComment', {
        sourceFn: (detail) =>
            store.select(
                detail.type === AttachmentCategoryType.DOCUMENT
                    ? selectPostCommentDocumentList(detail.postId)
                    : selectPostCommentMediaList(detail.postId),
            ),
        fetchFn: ({ detail, pageToken, pageSize }) => {
            store.dispatch(
                PostActions.fetchAttachmentsPage({
                    postId: detail.postId,
                    nextPageToken: pageToken,
                    attachmentType: detail.type,
                    pageSize,
                    entityTypes: ['comment'],
                }),
            );
        },
    });

    gallerySourceService.addSource('filePickers', {
        sourceFn: (detail) =>
            store.select(
                detail.type === AttachmentCategoryType.DOCUMENT
                    ? selectPostFilePickerDocumentList(detail.postId)
                    : selectPostFilePickerMediaList(detail.postId),
            ),
        fetchFn: ({ detail, pageToken, pageSize }) => {
            store.dispatch(
                PostActions.fetchAttachmentsPage({
                    postId: detail.postId,
                    nextPageToken: pageToken,
                    attachmentType: detail.type,
                    pageSize,
                    entityTypes: ['postFilePicker', 'screenFilePicker'],
                }),
            );
        },
    });

    gallerySourceService.addSource('comment', (detail) =>
        store
            .select(selectCommentAttachments(detail.postId, detail.commentId))
            .pipe(
                map((attachments) =>
                    (attachments || []).filter((a) =>
                        detail.type === AttachmentCategoryType.DOCUMENT
                            ? isFileAttachment(a)
                            : isMediaAttachment(a),
                    ),
                ),
                map(
                    (attachments) =>
                        <IListAttachments>{
                            attachments,
                            isLoading: false,
                            totalCount: attachments?.length,
                            pageTokenInfo: lastLoading(),
                            attachmentCategoryType: detail.type,
                        },
                ),
            ),
    );

    gallerySourceService.addSource('postFilePicker', {
        sourceFn: (detail) =>
            store.select(
                selectPostFilePickerAttachments(
                    detail.postId,
                    detail.filePicker.fieldId,
                ),
            ),
        fetchPreviewFn: (detail) =>
            store.dispatch(
                PostActions.fetchFilePickerTemporaryContentViewLink({
                    postId: detail.postId,
                    filePicker: detail.filePicker,
                    source: detail.feature,
                }),
            ),
    });

    gallerySourceService.addSource('linkedPostFilePicker', {
        sourceFn: (detail) =>
            detail.filePicker.postId != null &&
            detail.filePicker.fieldId != null &&
            detail.filePicker.communityId != null
                ? store.select(
                      selectLinkedPostFilePickerAttachments(
                          detail.filePicker.postId,
                          detail.filePicker.fieldId,
                          detail.filePicker.communityId,
                      ),
                  )
                : of(emptyAttachmentsList()),
        fetchPreviewFn: (detail) =>
            store.dispatch(
                PostActions.fetchFilePickerTemporaryContentViewLink({
                    postId: detail.filePicker.postId ?? detail.postId,
                    filePicker: detail.filePicker,
                    source: detail.feature,
                }),
            ),
    });

    gallerySourceService.addSource('screenFilePicker', {
        sourceFn: (detail) => {
            if (detail.filePicker.historyId) {
                return store
                    .select(
                        selectWorkflowHistoryAttachments(
                            detail.postId,
                            detail.filePicker.fieldId,
                            detail.filePicker.historyId,
                        ),
                    )
                    .pipe(
                        map((list: IListAttachments) => ({
                            ...list,
                            attachments: list.attachments.map((a) => ({
                                ...a,
                                versionNumber: undefined,
                            })),
                        })),
                    );
            } else {
                return store.select(
                    selectScreenFilePickerAttachments(
                        detail.postId,
                        detail.filePicker.fieldId,
                    ),
                );
            }
        },
        fetchPreviewFn: (detail) =>
            store.dispatch(
                PostActions.fetchFilePickerTemporaryContentViewLink({
                    postId: detail.postId,
                    filePicker: detail.filePicker,
                    source: detail.feature,
                }),
            ),
    });

    gallerySourceService.addSource('task', (detail) =>
        store
            .select(
                detail.type === AttachmentCategoryType.DOCUMENT
                    ? selectFileAttachments(detail.taskId)
                    : selectMediaAttachments(detail.taskId),
            )
            .pipe(
                map(
                    (attachments) =>
                        ({
                            attachments,
                            isLoading: false,
                            totalCount: attachments?.length,
                            pageTokenInfo: lastLoading(),
                            attachmentCategoryType: detail.type,
                        }) as IListAttachments,
                ),
            ),
    );
}
