import { Injectable } from '@angular/core';
import { HomeCommunityIndex } from '@modules/core';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import {
    copyFilter,
    emptyPostAttachmentsFilters,
} from '@modules/post/models/filter-post/filter-post.utils';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

type FiltersCache = Partial<Record<HomeCommunityIndex, IPostFilters>>;

@Injectable({ providedIn: 'root' })
export class FilterCommunitiesService {
    private filterCommunityListMap: FiltersCache;
    private currentfilterCommunityList: BehaviorSubject<IPostFilters>;

    constructor() {
        this.filterCommunityListMap = {};
        this.currentfilterCommunityList = new BehaviorSubject(
            emptyPostAttachmentsFilters(),
        );
    }

    public setFilterPostListCache(
        communityId: keyof FiltersCache,
        filters: IPostFilters,
    ): void {
        const nextFilters =
            communityId === 'dashboard' ? { ...filters } : copyFilter(filters);
        this.filterCommunityListMap[communityId] = nextFilters;
        this.currentfilterCommunityList.next(nextFilters);
    }

    public getFilterPostListCache(
        communityId: keyof FiltersCache,
    ): IPostFilters {
        return this.filterCommunityListMap[communityId]!;
    }

    public onChangeFilterPostListCache(): Observable<IPostFilters> {
        return this.currentfilterCommunityList.asObservable();
    }

    public flush(): void {
        this.filterCommunityListMap = {};
        this.currentfilterCommunityList.next(emptyPostAttachmentsFilters());
    }
}
