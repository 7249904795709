import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TimeoutsService {
    private timeouts: Record<string, NodeJS.Timeout> = {};

    /**
     * Set a new timeout identified it by a key.
     * If a timeout for the same key already exists, it is cleared befor set the new one.
     * @param key The extenal identified for the timeout.
     * @param fn The function to execute.
     * @param ms The number of milliseconds to wait before executing.
     * @returns The internal id of the timer.
     */
    setTimeout(key: string, fn: () => void, ms: number): NodeJS.Timeout {
        const existingTimeoutId = this.timeouts[key];
        if (existingTimeoutId != null) {
            clearTimeout(existingTimeoutId);
        }
        console.debug(
            `setTimeout ${key} ms:${ms} ${
                existingTimeoutId != null ? '(replacing existing one)' : ''
            }`,
        );
        const id = setTimeout(fn, ms);
        this.timeouts[key] = id;
        return id;
    }

    clearTimeout(key: string): void {
        const existingTimeoutId = this.timeouts[key];
        if (existingTimeoutId != null) {
            console.debug(`clearTimeout ${key}`);
            clearTimeout(existingTimeoutId);
            delete this.timeouts[key];
        }
    }
}
