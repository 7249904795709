import { Injectable } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { Store } from '@ngrx/store';
import { initSyncToken } from '../store/differential/differential.actions';

@Injectable()
export class CommunitiesModuleService {
    private initialized = false;

    constructor(
        private store: Store,
        private authService: AuthService,
    ) {}

    initialize(): void {
        if (!this.initialized) {
            this.initialized = true;

            this.authService.login$.subscribe(() => {
                this.store.dispatch(initSyncToken());
            });
        }
    }
}
