import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PostSelectionService {
    private selectedPostsSet = new Set<number>();
    private selectedPosts$ = new BehaviorSubject(new Set<number>());

    getSelectedPosts$(): Observable<Set<number>> {
        return this.selectedPosts$.asObservable();
    }

    clearSelection(): void {
        this.selectedPostsSet.clear();
        this.emit();
    }

    toggleSelectedPost(postId: number): void {
        if (this.selectedPostsSet.has(postId)) {
            this.unselectPost(postId);
        } else {
            this.selectPost(postId);
        }
    }

    selectPost(postId: number): void {
        this.selectedPostsSet.add(postId);
        this.emit();
    }

    unselectPost(postId: number): void {
        this.selectedPostsSet.delete(postId);
        this.emit();
    }

    private emit(): void {
        this.selectedPosts$.next(new Set(this.selectedPostsSet));
    }
}
